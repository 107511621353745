import dynamic from 'next/dynamic';

export default {
    PageIframe: dynamic(() => import('./PageIframe')),
    PagePdfResource: dynamic(() => import('./PagePdfResource')),
    PagePdfMethod: dynamic(() => import('./PagePdfMethod')),
    PageStories: dynamic(() => import('./PageStories')),
    PageResourceList: dynamic(() => import('./PageResourceList')),
    PageMethodList: dynamic(() => import('./PageMethodList')),
    PageResource: dynamic(() => import('./PageResource')),
    PageRegionList: dynamic(() => import('./PageRegionList')),
    PageSearch: dynamic(() => import('./PageSearch')),
    PagePdfInformationArrangement: dynamic(() => import('./PagePdfInformationArrangement')),
    PagePdfPosterArrangement: dynamic(() => import('./PagePdfPosterArrangement')),
    PageOffice: dynamic(() => import('./PageOffice')),
    PagePerson: dynamic(() => import('./PagePerson')),
    PageNotFound: dynamic(() => import('./PageNotFound')),
    PageContact: dynamic(() => import('./PageContact')),
    PageArrangementSearch: dynamic(() => import('./PageArrangementSearch')),
    PageArrangement: dynamic(() => import('./PageArrangement')),
    PageMethod: dynamic(() => import('./PageMethod')),
    PageNewsList: dynamic(() => import('./PageNewsList')),
    PageNews: dynamic(() => import('./PageNews')),
    PageEditorial: dynamic(() => import('./PageEditorial')),
    PageEditorialList: dynamic(() => import('./PageEditorialList')),
    PageRegion: dynamic(() => import('./PageRegion')),
    PageSubpage: dynamic(() => import('./PageSubpage')),
    PageArticle: dynamic(() => import('./PageArticle')),
    PageHome: dynamic(() => import('./PageHome')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};
